import React, { useEffect, useState, useRef } from 'react';

import PasswordReset from './PasswordReset.js';
import Preferences from './Preferences.js';

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';
import InputField from "../../Components/InputField.js";
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TuneIcon from '@mui/icons-material/Tune';

function AdminBoard(props){
    const requirementsBlock = useRef(null);

    const session = props?.session;
    const sessionUser = props?.session?.user?.data;
    const viewType = session?.env?.viewport?.viewType;

    const [selectedTab, setSelectedTab] = useState(props?.tab);

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);

    const [attributeForm, setAttributeForm] = useState(undefined);
    const [currentlyEditing, setCurrentlyEditing] = useState(false);
    const [editingAccount, setEditingAccount] = useState(false);
    const [creatingUser, setCreatingUser] = useState(false);
    const [tempUploadPermission, setTempUploadPermission] = useState(null);
    const [tempDownloadPermission, setTempDownloadPermission] = useState(null);
    const [tempCreatePermission, setTempCreatePermission] = useState(null);
    const [tempResearchPermission, setTempResearchPermission] = useState(null);

    const [removingUser, setRemovingUser] = useState(false);

    const [removeClicked, setRemoveClicked] = useState(false);

    const [settingsWidget, setSettingsWidget] = useState('accountInfo')

    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);

    const [getCalled, setGetCalled] = useState(false);

    const [missingInputs, setMissingInputs] = useState(false);

    const emptyUser = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        last_login: '',
        uploadAllowed: '',
        downloadAllowed: '',
        creationAllowed:'',
        researchAllowed:'',
    }

    const [currentUser, setCurrentUser] = useState({
        first_name: session?.user?.data?.firstName,
        last_name: session?.user?.data?.lastName,
        email: session?.user?.data?.email,
        phone_number: session?.user?.data?.phoneNumber,
        last_login: '',
        uploadAllowed: session?.user?.data?.views?.includes('Upload'),
        downloadAllowed: session?.user?.data?.permissions?.includes('download'),
        creationAllowed: session?.user?.data?.permissions?.includes('accMgr'),
        researchAllowed: session?.user?.data?.views?.includes("Research Hub"),
    })

    const [selectedUser, setSelectedUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        last_login: '',
        uploadAllowed: '',
        downloadAllowed: '',
        creationAllowed:'',
        researchAllowed:'',
    })

    const uploadOptions = [
        { value: true, label: "Allowed" },
        { value: false, label: "Not Allowed" },
    ];

    const [currentContractLimit, setCurrentContractLimit] = useState(0);
 
    useEffect(() => {
        Object.keys(session?.account?.data).forEach((key, index) => {
            if(key === session?.user?.data?.accountID){
                setCurrentContractLimit(session?.account?.data?.[key]?.contractedRecords);
            }
        });
    }, []); 

    const renderInputField = (label, value ) => {
        if((label === 'Last Login' || label === 'Contract Limit') && creatingUser){
           
        }
        else if(label === 'Last Login' || label === 'Contract Limit' || (label === 'Phone Number' && !creatingUser) || (label === 'Email' && !creatingUser)){
            return (
                <InputField 
                    value={value ? value : "N/A"} 
                    placeholder={label} 
                    name={findKeyByAttribute(session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData, 'friendlyTerm', label)} 
                    functionPointer={session?.env?.functions?.updateInput} 
                    readOnly={true} 
                    setter={setSelectedUser}
                />
              );
        }
        else if (selectedTab === "manageUsers") {
            return (
            <InputField 
                value={value ? value : ""} 
                placeholder={label} 
                name={findKeyByAttribute(session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData, 'friendlyTerm', label)} 
                functionPointer={session?.env?.functions?.updateInput} 
                readOnly={!currentlyEditing && !creatingUser} 
                setter={setSelectedUser}
                error={label === 'Email' ? (invalidEmail ? ' ' : '') : label === 'Phone Number' ? (invalidPhone ? ' ' : '') : undefined}
            />         
            );
        }
        else if (selectedTab === "settings") {
            return (
            <InputField 
                value={value ? value : ""} 
                placeholder={label} 
                name={findKeyByAttribute(session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData, 'friendlyTerm', label)} 
                functionPointer={session?.env?.functions?.updateInput} 
                readOnly={!editingAccount} 
                setter={setCurrentUser}
                // {...(missingInputs ? { error:' '} : {})}
            />         
            );
        }
        else {
            return (
            <InputField 
                value={value ? value : ""} 
                placeholder={label} 
                name={findKeyByAttribute(session?.individual.data[session?.handler?.data?.currentAccountID].individualAttributesData, 'friendlyTerm', label)} 
                functionPointer={session?.env?.functions?.updateInput} 
                readOnly={true} 
                setter={setSelectedUser}
                // {...(missingInputs ? { error:' '} : {})}
            />

            );
        }
    };

    useEffect(() => {

    }, []); 

    function isValidEmail(email) {
        return email.includes('@') && email.includes('.');
    }
    
    function isValidPhoneNumber(phoneNumber) {
        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        const phoneNumberLength = numericPhoneNumber.length;
    
        return phoneNumberLength === 0 || phoneNumberLength === 10 || phoneNumberLength === 11;
    }
    

    function findKeyByAttribute(obj, attr, value) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key][attr]?.toUpperCase() === value?.toUpperCase() ) {
                return key;
            }
        }
        return null;
    }

    //newKey = findKeyByAttribute(session?.individual.data.individualAttributesData, 'friendlyTerm', header);

    const [teamMembers, setTeamMembers] = useState([
        // {
        //   first_name: 'John',
        //   last_name: 'Doe',
        //   email: 'john@example.com',
        //   phone_number: '123-456-7890',
        //   last_login: '2024-03-25',
        //   uploadAllowed: 'allowed',
        //   downloadAllowed: 'allowed',
        //   creationAllowed: 'notAllowed'
        // },
    ]);

    const [tempTeamMembers, setTempTeamMembers] = useState([]);

    const [tempUserData, setTempUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        last_login: '',
        uploadAllowed: '',
        downloadAllowed: '',
        creationAllowed: '',
        researchAllowed: '',
        recordID: '',
    });

    const [tempAccountData, setTempAccountData] = useState({});

    function createNewTeamMember(firstName, lastName, email, phoneNumber, lastLogin, uploadAllowed, downloadAllowed, creationAllowed, researchAllowed, recordID, teamMembers) {
        const newMember = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          last_login: lastLogin,
          uploadAllowed: uploadAllowed,
          downloadAllowed: downloadAllowed,
          creationAllowed: creationAllowed,
          researchAllowed: researchAllowed,
          recordID: recordID
        };
      
        // Push the new member object into the teamMembers array
        teamMembers.push(newMember);

        return teamMembers;
    }

    function updateAttributeInTeamMembers(index, attribute, newValue) {
        setTeamMembers(prevTeamMembers => {
          // Make a copy of the teamMembers array to avoid mutating state directly
          const updatedTeamMembers = [...prevTeamMembers];
          
          // Update the specified attribute of the object at the given index
          updatedTeamMembers[index] = {
            ...updatedTeamMembers[index], // Keep existing attributes
            [attribute]: newValue // Update the specified attribute
          };
      
          return updatedTeamMembers; // Return the updated array
        });
    }

    function updateAttributesInTeamMembers(index, attributesToUpdate) {
        setTeamMembers(prevTeamMembers => {
    
            const updatedTeamMembers = [...prevTeamMembers];

            updatedTeamMembers[index] = {
            ...updatedTeamMembers[index], 
            ...attributesToUpdate 
            };

            return updatedTeamMembers; 
        });
    }
      

    function allAttributesFilled(selectedUser) {
        for (const key in selectedUser) {
          // Skip checking 'last_login' and 'phone_number'
          if (key === 'last_login' || key === 'phone_number') continue;

          if (key === 'researchAllowed'){
            if (!session?.user?.data?.views?.includes("Research Hub")){
                continue;
            }
          }
      
          // If any attribute is empty, return false
          if (selectedUser[key] === '') {
            return false;
          }
        }
        // If all attributes are filled, return true
        return true;
    }

    useEffect(() => {
        // if(selectedTab === 'manageUsers'){
            //getUsers();
        // }
    }, []); 

    useEffect(() => {
        if(selectedTab === 'manageUsers'){
            getUsers();
        }
    }, [selectedTab]); 

    useEffect(() => { 
    }, [teamMembers]); 

    useEffect(() => { 
        if(removeClicked){
            setSelectedUser(() => ({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                last_login: '',
                uploadAllowed: '',
                downloadAllowed: '',
                creationAllowed: '',
                researchAllowed: '',
            }));
            setRemoveClicked(false);
        }
    }, [removeClicked]); 

    // useEffect(() => { 
        
    // }, [userCreatedToggle]);

    function convertISOToMMDDYYYY(dateString) {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
    
        // Check if any of the fields result in NaN
        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            return 'N/A';
        }
    
        return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    }

    function getUsers(){  
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {};

        session?.env?.functions?.buildFetchRequest("/manage/users", paramVals, null, null, "GET")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                tempTeamMembers.splice(0, tempTeamMembers.length);
                for (const user of Object.values(resData?.users)){      
                    if(sessionUser.userID !== user?.recordID && user?.active === true && user?.relatedAccountID == session?.handler?.data?.currentAccountID){
                        const uploadPerm = user?.views?.includes("Upload");
                        const downloadPerm = user?.permissions?.includes("download");
                        const createPerm = user?.permissions?.includes("accMgr");
                        const researchPerm = user?.views?.includes("Research Hub");
                        createNewTeamMember(user?.firstName, user?.lastName, user?.email, user?.phoneNumber, convertISOToMMDDYYYY(user?.lastLogin), uploadPerm, downloadPerm, createPerm, researchPerm, user?.recordID, tempTeamMembers);
                    }
                    if(sessionUser.userID === user?.recordID){
                        setCurrentUser(
                            {
                                first_name: user?.firstName,
                                last_name: user?.lastName,
                                email: user?.email,
                                phone_number: user?.phoneNumber,
                                last_login: convertISOToMMDDYYYY(user?.lastLogin),
                                uploadAllowed: session?.user?.data?.views?.includes("Upload"),
                                downloadAllowed: session?.user?.data?.permissions?.includes("download"),
                                creationAllowed: session?.user?.data?.permissions?.includes("accMgr"),
                                researchAllowed: session?.user?.data?.views?.includes("Research Hub"),
                                recordID: user?.recordID
                            }
                        )
                    }
                } 
                setTeamMembers(tempTeamMembers);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    function addUser(newMember){
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            firstName: newMember.first_name,
            lastName: newMember.last_name,
            email: newMember.email,
            phoneNumber: newMember.phone_number,
            uploadAllowed: newMember.uploadAllowed, 
            downloadAllowed: newMember.downloadAllowed,
            creationAllowed: newMember.creationAllowed,
            researchAllowed: newMember.researchAllowed,
            accountID: sessionUser.accountID
        };
        session?.env?.functions?.buildFetchRequest("/manage/user", paramVals, null, null, "POST")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 201){
                updateAttributeInTeamMembers(teamMembers[teamMembers.length-1], 'recordID', resData.recordID);
                getUsers();
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    function editUser(editedMember){
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            firstName: editedMember.first_name,
            lastName: editedMember.last_name,
            email: editedMember.email,
            phoneNumber: editedMember.phone_number,
            uploadAllowed: editedMember.uploadAllowed, 
            downloadAllowed: editedMember.downloadAllowed,
            creationAllowed: editedMember.creationAllowed,
            researchAllowed: editedMember.researchAllowed,
            userID: editedMember.recordID,
            // accountID: sessionUser.accountID,    
        };
        session?.env?.functions?.buildFetchRequest("manage/user", paramVals, null, null, "PUT")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
   
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    function deleteUser(recordID){
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            // firstName: editedMember.first_name,
            // lastName: editedMember.last_name,
            // email: editedMember.email,
            // phoneNumber: editedMember.phone_number,
            // uploadAllowed: editedMember.uploadAllowed, 
            // downloadAllowed: editedMember.downloadAllowed,
            // creationAllowed: editedMember.creationAllowed,
            userID: recordID,
            accountID: session?.handler?.data?.currentAccountID,    
        };
        session?.env?.functions?.buildFetchRequest("manage/user", paramVals, null, null, "DELETE")
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                getUsers();
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }


    const TeamMemberList = () => {
        return (
          <div>
            {teamMembers.map((member, index) => (
              <div key={index} className={`criterionBlock f cC g e ${selectedItemIndex === index && !creatingUser ? 'selectedAccount' : ''} ${currentlyEditing || creatingUser ? '' : 'p'}`} 
                onClick= {() => {
                    setTempUploadPermission(null);
                    setTempDownloadPermission(null);
                    setTempCreatePermission(null);
                    setTempResearchPermission(null);

                    !currentlyEditing && !creatingUser &&
                        setSelectedUser(() => ({
                            first_name: member.first_name,
                            last_name: member.last_name,
                            email: member.email,
                            phone_number: member.phone_number,
                            last_login: member.last_login,
                            uploadAllowed: member.uploadAllowed,
                            downloadAllowed: member.downloadAllowed,
                            creationAllowed: member.creationAllowed,
                            researchAllowed: member.researchAllowed,
                            recordID: member.recordID,
                        }));
                    !currentlyEditing && setSelectedItemIndex(index);
                }}
              >
                <div className="bold cL gCW s f">
                  <div className='f'>{member.first_name} {member.last_name}</div>
                </div>
              </div>
            ))}
          </div>
        );
    };

    function printOverlayContent() {

        switch (selectedTab) {
            case 'manageUsers':
                return (
                    <div className='manageAccountsContent f g'>
                        <div className="leftPanel f g s b">
                            <div className="header f cC bold">
                                Account Name
                            </div>
                            <div className="userList f g dP oA gR2">
                                <div
                                    className={`criterionBlock create cC g p e${creatingUser ? " active" : ''}`}
                                    onClick={() => {
                                        // setCurrentlyEditing(true);
                                        setCreatingUser(true);
                                        setTempUploadPermission(null);
                                        setTempDownloadPermission(null);
                                        setTempCreatePermission(null);
                                        setTempResearchPermission(null);
                                        setSelectedItemIndex(null);
                                        {setSelectedUser(() => ({
                                            first_name: '',
                                            last_name: '',
                                            email: '',
                                            phone_number: '',
                                            last_login: '',
                                            uploadAllowed: '',
                                            downloadAllowed: '',
                                            creationAllowed: '',
                                            researchAllowed: '',
                                        }));}
                                    }}
                                >
                                    <div className={`bold f cC${props?.viewType === "mobile" ? " gC2" : ''}`}>
                                        {creatingUser ?
                                            "Creating New User"
                                        :
                                            "Create New User"
                                        }
                                    </div>
                                    <div className={`f cC gC2 zTPC${props?.viewType === "mobile" ? " gC3" : ''}`}>
                                        {creatingUser ?
                                            <EditIcon/>
                                        :
                                            <AddCircleIcon/>
                                        }
                                    </div>
                                </div>
                                {TeamMemberList()}
                            </div>
                        </div>
                        <div className="rightPanel f g">
                            <div className={`header ${(!creatingUser && selectedItemIndex !== null) ? 'manageHeaderRow' : ''} dP f cC g dG bold`}>
                                {(creatingUser || selectedItemIndex === null) && <div className='t'>Profile</div>}
                                {!creatingUser && selectedItemIndex !== null && <div className='gC2'>Profile</div>}
                                {currentlyEditing && !creatingUser && selectedItemIndex !== null && !removingUser && 
                                    <div className='btnWIcon gC3 cC dP editUserBtn p bR deleteUser'
                                        onClick={()=> {setRemovingUser(true); 
                                                        session?.env?.setNestedOverlay({
                                                            name: 'removeUser',
                                                            params: {setRemovingUser: setRemovingUser, 
                                                                    deleteUser: deleteUser, 
                                                                    setRemoveClicked: setRemoveClicked, 
                                                                    setSelectedItemIndex: setSelectedItemIndex,
                                                                    setCurrentlyEditing: setCurrentlyEditing,
                                                                    selectedUser: selectedUser
                                                            }
                                                        });}
                                                }
                                    > 
                                        {viewType==='full' && <div>Remove User</div>} <DeleteIcon/>
                                    </div> }
                                {!currentlyEditing && !creatingUser && selectedItemIndex !== null && !removingUser && <div className='btnWIcon gC4 cC dP editUserBtn p bR' onClick={()=> {setTempUserData(selectedUser); setCurrentlyEditing(true);}}>{viewType==='full' && <div>Edit User</div>} <EditIcon/></div> }
                                {currentlyEditing && !creatingUser && selectedItemIndex !== null && !removingUser && <div className='btnWIcon inactive gC4 cC dP editUserBtn p bR'> {viewType==='full' && <div>Edit User</div>} <EditIcon/></div> }        
                            </div>
                            {/* {removingUser &&
                                <div className='adminBoardOverlayWarning bR f cC g'>
                                    <div className='f cC gR1 gC2'>
                                        <div className="close btn f p f" onClick={()=>{setRemovingUser(false)}}>
                                            <CloseRoundedIcon/>
                                        </div>
                                    </div>
                                    <div className='cC bold f gR2 gC1 colSpan2'>Are You Sure You Want <br/>To Remove This User?</div> 
                                    <div className='btnWIcon bC dP p bR gR3 gC1 colSpan2' onClick={()=>{
                                            deleteUser(selectedUser?.recordID);
                                            setRemovingUser(false); 
                                            setRemoveClicked(true);
                                            setSelectedItemIndex(null);
                                            setCurrentlyEditing(false);
                                        }}>
                                        Confirm
                                    </div>                           
                                </div>
                            } */}
                            <div className={'f g dP e profileScroll'}>
                                <div className="defaultForm f g dG fC oA">
                                    <div className="row g s fC">
                                        <div className="header bold cL s g dG">
                                            <BadgeIcon/>
                                            <div className="f cC gCW">
                                                Personal Information
                                            </div>
                                            {selectedUser?.internal_id &&
                                                <div className="group f cC s e bR gCW">
                                                    {selectedUser?.internal_id}
                                                </div>
                                            }
                                            {selectedUser?.internal_group &&
                                                <div className="group f cC s e bR gCW">
                                                    {selectedUser?.internal_group}
                                                </div>
                                            }
                                        </div>
                                        

                                        <div className="rowData name g s">
                                            {renderInputField('First Name', selectedUser?.first_name)}
                                            {renderInputField('Last Name', selectedUser?.last_name)}
                                            {/* {selectedItemIndex !== null && <div className='f cC'><div className='btnWIcon cC editUserBtn p bR' onClick={()=> {setTempUserData(selectedUser); setCurrentlyEditing(true);}}>Edit Details <EditIcon/></div></div>} */}                                           
                                        </div>
                                        <div className="rowData name g s">
                                            {renderInputField('Email', selectedUser?.email)}
                                            {renderInputField('Phone Number', selectedUser?.phone_number)}
                                        </div>
                                        <div className="rowData name g s">
                                            {renderInputField('Last Login', selectedUser?.last_login)}
                                        </div>
                                    </div>

                                    <div className="row g s userPermissions">
                                        <div className="header permissionsHeader bold cL s g dG">
                                            <ListAltIcon/>
                                            <div className="f cC gCW">
                                                Permissions
                                            </div>
                                        </div>
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR2' : 'gC1 gR2'} dG`}>
                                            Upload
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR3' : 'gC1 gR3'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <UploadTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempUploadPermission === null ? selectedUser?.uploadAllowed === option.value ? " active" : ""
                                                            :
                                                            tempUploadPermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    uploadAllowed: option?.value,
                                                                }));
                                                            selectedItemIndex !== null &&
                                                                setTempUploadPermission(option?.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR2' : 'gC1 gR4'} dG`}>
                                            Download
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR3' : 'gC1 gR5'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <DownloadTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempDownloadPermission === null ? selectedUser?.downloadAllowed === option.value ? " active" : ""
                                                            :
                                                            tempDownloadPermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    downloadAllowed: option.value,
                                                                }));
                                                            !creatingUser && selectedItemIndex !== null &&
                                                                setTempDownloadPermission(option.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR4' : 'gC1 gR6'} dG`}>
                                            Account Creation
                                        </div>
                                        <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR5' : 'gC1 gR7'} s`}>
                                            <div className="trackBar cC g fR">
                                                <div className="f cC">
                                                    <PersonAddAltTwoToneIcon/>
                                                </div>
                                                {uploadOptions.map((option) => (
                                                    <div
                                                        key={option.value}
                                                        className={`option bold f cC dP p${
                                                            tempCreatePermission === null ? selectedUser?.creationAllowed === option.value ? " active" : ""
                                                            :
                                                            tempCreatePermission === option.value ? " active" : ""
                                                        }`}
                                                        onClick={() => {
                                                            
                                                            creatingUser && 
                                                                setSelectedUser((prev) => ({
                                                                    ...prev,
                                                                    creationAllowed: option.value,
                                                                }));
                                                            selectedItemIndex !== null &&
                                                                setTempCreatePermission(option.value);
                                                        }}
                                                    >
                                                        {option.label}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {session?.user?.data?.views?.includes("Research Hub") && 
                                            <>
                                                <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR4' : 'gC1 gR8'} dG`}>
                                                        Research Hub
                                                </div>
                                                <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR5' : 'gC1 gR9'} s`}>
                                                    <div className="trackBar cC g fR">
                                                        <div className="f cC">
                                                            <TravelExploreIcon/>
                                                        </div>
                                                        {uploadOptions.map((option) => (
                                                            <div
                                                                key={option.value}
                                                                className={`option bold f cC dP p${
                                                                    tempResearchPermission === null ? selectedUser?.researchAllowed === option.value ? " active" : ""
                                                                    :
                                                                    tempResearchPermission === option.value ? " active" : ""
                                                                }`}
                                                                onClick={() => {    
                                                                    creatingUser && 
                                                                        setSelectedUser((prev) => ({
                                                                            ...prev,
                                                                            researchAllowed: option.value,
                                                                        }));
                                                                    selectedItemIndex !== null &&
                                                                        setTempResearchPermission(option.value);
                                                                }}                                                       
                                                            >
                                                                {option.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={`actionBar f cC fR dP ${viewType==="mobile" ? '' : 'g'}`}>
                                {(currentlyEditing || creatingUser) && !removingUser &&
                                    <div className="btnWIcon g cC f bR s e p oH gC1 bold"
                                        onClick={()=>{
                                            {currentlyEditing && setSelectedUser(tempUserData);}                           
                                            {creatingUser && setSelectedUser(() => ({
                                                first_name: '',
                                                last_name: '',
                                                email: '',
                                                phone_number: '',
                                                last_login: '',
                                                uploadAllowed: '',
                                                downloadAllowed: '',
                                                creationAllowed: '',
                                                researchAllowed: '',
                                            }));}
                                            if (creatingUser) {
                                                setInvalidPhone(false);
                                                setInvalidEmail(false);
                                                setSelectedItemIndex(null); 
                                            }    
                                            setCurrentlyEditing(false); setCreatingUser(false); 
                                            setTempCreatePermission(null); setTempUploadPermission(null); setTempDownloadPermission(null); setTempResearchPermission(null);
                                        }}
                                    >
                                        Cancel
                                    </div>
                                }                               
                                {(!removingUser && (currentlyEditing || (((tempCreatePermission !== null) && tempCreatePermission !== selectedUser.creationAllowed) || 
                                    ((tempUploadPermission !== null) && tempUploadPermission !== selectedUser.uploadAllowed) || 
                                    ((tempDownloadPermission !== null) && tempDownloadPermission !== selectedUser.downloadAllowed) || 
                                    ((tempResearchPermission !== null) && tempResearchPermission !== selectedUser.researchAllowed) ||
                                    (allAttributesFilled(selectedUser) && creatingUser) ) )
                                 ) ?
                                    <div className="btnWIcon g cC f bR s e p gC3 bold" style={{ position: 'relative' }}
                                        onClick={() => {
                                            if (creatingUser && allAttributesFilled(selectedUser)) {
                                                if(isValidEmail(selectedUser?.email) && isValidPhoneNumber(selectedUser?.phone_number)){
                                                    setInvalidEmail(false);
                                                    setInvalidPhone(false);
                                                    setTeamMembers(createNewTeamMember(
                                                        selectedUser?.first_name,
                                                        selectedUser?.last_name,
                                                        selectedUser?.email,
                                                        selectedUser?.phone_number,
                                                        selectedUser?.last_login,
                                                        selectedUser?.uploadAllowed,
                                                        selectedUser?.downloadAllowed,
                                                        selectedUser?.creationAllowed,
                                                        selectedUser?.researchAllowed,
                                                        null,
                                                        teamMembers
                                                    ));
                                                    addUser(
                                                        {
                                                            first_name: selectedUser?.first_name,
                                                            last_name: selectedUser?.last_name,
                                                            email: selectedUser?.email,
                                                            phone_number: selectedUser?.phone_number,
                                                            last_login: selectedUser?.last_login,
                                                            uploadAllowed: tempUploadPermission !== null ? tempUploadPermission : selectedUser?.uploadAllowed,
                                                            downloadAllowed: tempDownloadPermission !== null ? tempDownloadPermission : selectedUser?.downloadAllowed,
                                                            creationAllowed: tempCreatePermission !== null ? tempCreatePermission : selectedUser?.creationAllowed,
                                                            researchAllowed: tempResearchPermission !== null ? tempResearchPermission : selectedUser?.researchAllowed,
                                                            recordID: null,
                                                        }
                                                    );
                                                    setSelectedItemIndex(null);
                                                    setCreatingUser(false); 
                                                    setMissingInputs(false);
                                                    {creatingUser && setSelectedUser(() => ({
                                                        first_name: '',
                                                        last_name: '',
                                                        email: '',
                                                        phone_number: '',
                                                        last_login: '',
                                                        uploadAllowed: '',
                                                        downloadAllowed: '',
                                                        creationAllowed: '',
                                                        researchAllowed: '',
                                                    }));}
                                                }else{
                                                    if (!isValidEmail(selectedUser?.email)){
                                                        setInvalidEmail(true);
                                                    }
                                                    if(!isValidPhoneNumber(selectedUser?.phone_number)){
                                                        setInvalidPhone(true);
                                                    }
                                                }
                                            }
                                            else{
                                                setMissingInputs(true);
                                            }
                                            if(currentlyEditing){
                                                updateAttributesInTeamMembers(selectedItemIndex, { 
                                                    first_name: selectedUser?.first_name,
                                                    last_name: selectedUser?.last_name,
                                                    email: selectedUser?.email,
                                                    phone_number: selectedUser?.phone_number,
                                                    uploadAllowed: tempUploadPermission !== null ? tempUploadPermission : selectedUser?.uploadAllowed,
                                                    downloadAllowed: tempDownloadPermission !== null ? tempDownloadPermission : selectedUser?.downloadAllowed,
                                                    creationAllowed: tempCreatePermission !== null ? tempCreatePermission : selectedUser?.creationAllowed,
                                                    researchAllowed: tempResearchPermission !== null ? tempResearchPermission : selectedUser?.researchAllowed,
                                                });
                                                editUser(
                                                    {
                                                        first_name: selectedUser?.first_name !== tempUserData?.first_name ? selectedUser?.first_name: undefined, 
                                                        last_name: selectedUser?.last_name !== tempUserData?.last_name ? selectedUser?.last_name : undefined, 
                                                        email: selectedUser?.email !== tempUserData?.email ? selectedUser?.email : undefined,
                                                        phone_number: selectedUser?.phone_number !== tempUserData?.phone_number ? selectedUser?.phone_number: undefined, 
                                                        last_login: selectedUser?.last_login,
                                                        uploadAllowed: tempUploadPermission !== null ? tempUploadPermission : selectedUser?.uploadAllowed,
                                                        downloadAllowed: tempDownloadPermission !== null ? tempDownloadPermission : selectedUser?.downloadAllowed,
                                                        creationAllowed: tempCreatePermission !== null ? tempCreatePermission : selectedUser?.creationAllowed,
                                                        researchAllowed: tempResearchPermission !== null ? tempResearchPermission : selectedUser?.researchAllowed,
                                                        recordID: selectedUser?.recordID
                                                    }
                                                );
                                                setCurrentlyEditing(false);
                                            }
                                            
                                            if( !currentlyEditing && (
                                                ((tempUploadPermission !== null) && (selectedUser?.uploadAllowed !== tempUploadPermission)) ||
                                                ((tempDownloadPermission !== null) && (selectedUser?.downloadAllowed !== tempDownloadPermission)) ||
                                                ((tempCreatePermission !== null) && (selectedUser?.creationAllowed !== tempCreatePermission)) ||
                                                ((tempResearchPermission !== null) && (selectedUser?.researchAllowed !== tempResearchPermission)) 
                                                )
                                            )
                                            {                             
                                                (tempUploadPermission !== null) && updateAttributeInTeamMembers(selectedItemIndex, 'uploadAllowed', tempUploadPermission);
                                                (tempDownloadPermission !== null) && updateAttributeInTeamMembers(selectedItemIndex, 'downloadAllowed', tempDownloadPermission);
                                                (tempCreatePermission !== null) && updateAttributeInTeamMembers(selectedItemIndex, 'creationAllowed', tempCreatePermission);
                                                (tempResearchPermission !== null) && updateAttributeInTeamMembers(selectedItemIndex, 'researchAllowed', tempResearchPermission);
                                                setSelectedUser((prev) => ({
                                                    ...prev,
                                                    uploadAllowed: tempUploadPermission !== null ? tempUploadPermission : selectedUser?.uploadAllowed,
                                                    downloadAllowed: tempDownloadPermission !== null ? tempDownloadPermission : selectedUser?.downloadAllowed,
                                                    creationAllowed: tempCreatePermission !== null ? tempCreatePermission : selectedUser?.creationAllowed,
                                                    researchAllowed: tempResearchPermission !== null ? tempResearchPermission : selectedUser?.researchAllowed,
                                                }));
                                                editUser({
                                                    uploadAllowed: tempUploadPermission !== null ? tempUploadPermission : selectedUser?.uploadAllowed,
                                                    downloadAllowed: tempDownloadPermission !== null ? tempDownloadPermission : selectedUser?.downloadAllowed,
                                                    creationAllowed: tempCreatePermission !== null ? tempCreatePermission : selectedUser?.creationAllowed,
                                                    researchAllowed: tempResearchPermission !== null ? tempResearchPermission : selectedUser?.researchAllowed,
                                                    recordID: selectedUser?.recordID
                                                });
                                            }

                                        }}
                                    >
                                        {creatingUser && (invalidEmail || invalidPhone) && <div className='cC' style={{ position: 'absolute', top: '-50px',  zIndex:'1', color:'red' }}><ErrorOutlineIcon/><p>Invalid Inputs</p></div> }
                                        {creatingUser ? 'Save' : 'Update'}
                                    </div>
                                    :
                                    !removingUser && 
                                        <div className="btnWIcon g cC f bR s e p oH gC3 bold inactive">
                                            {creatingUser ? 'Save' : 'Update'} 
                                        </div>
                                    
                                }
                            </div>
                        </div>
                    </div>
                );
            case 'settings':
                return (
                    <div className='settingsTabContent g'>
                        <div className='f gC1 g settingsLeftPanel'>
                            <div className='gC1 gR1 cC dP bold'><div style={{fontSize:'20px'}}>Settings</div></div>
                            <div className={`gC1 gR2 dP p cC ${settingsWidget==='accountInfo' ? 'settingsWidget' : 'settingsWidgetInactive'}`}onClick={() => {setSettingsWidget('accountInfo')}}>
                                <div className='cC dG'>Account Information<FolderSharedIcon/></div>
                            </div> 
                            <div className={`gC1 gR3 dP p cC ${settingsWidget==='emailPreferences' ? 'settingsWidget' : 'settingsWidgetInactive'}`} onClick={() => {setSettingsWidget('emailPreferences')}}>
                                <div className='cC dG'>Preferences<TuneIcon/></div>
                            </div> 
                            <div className={`gC1 gR4 dP p cC ${settingsWidget==='passReset' ? 'settingsWidget' : 'settingsWidgetInactive'}`} onClick={() => {setSettingsWidget('passReset')}}>
                                <div className='cC dG'>Password Reset<PasswordIcon/></div>
                            </div> 
                        </div>

                        <div className='f gC2 g settingsRightPanel'>
                            <div className={`bR settingsWidgetContent g ${settingsWidget==='emailPreferences' ? 'preferences' : ''}`}>
                                {settingsWidget === 'accountInfo' && 
                                    <>
                                    <div className='bold dP g accountHeaderRow'><div className='gC2'>Account Information</div>
                                        {!editingAccount && <div className='btnWIcon gC3 cC dP editUserBtn p bR' onClick={()=> {setTempAccountData(currentUser); setEditingAccount(true);}}>Edit User<EditIcon/></div> }
                                        {editingAccount && <div className='btnWIcon inactive gC3 cC dP editUserBtn p bR'> Edit User <EditIcon/></div> }
                                    </div>
                                    <div className="defaultForm f g dG fC oA dP">
                                        <div className="row g s fC">
                                            <div className="header bold cL s g dG">
                                                <BadgeIcon/>
                                                <div className="f cC gCW">
                                                    Personal Information
                                                </div>
                                                {selectedUser?.internal_id &&
                                                    <div className="group f cC s e bR gCW">
                                                        {selectedUser?.internal_id}
                                                    </div>
                                                }
                                                {selectedUser?.internal_group &&
                                                    <div className="group f cC s e bR gCW">
                                                        {selectedUser?.internal_group}
                                                    </div>
                                                }
                                            </div>
                                            <div className="rowData name g s">
                                                {renderInputField('First Name', currentUser?.first_name)}
                                                {renderInputField('Last Name', currentUser?.last_name)}
                                                {/* <div className='f cC'><div className='btnWIcon cC editUserBtn p bR' onClick={()=> {setTempAccountData(currentUser); setEditingAccount(true);}}>Edit Details <EditIcon/></div></div> */}
                                            </div>
                                            <div className="rowData name g s">
                                                {renderInputField('Email', currentUser?.email)}
                                                {renderInputField('Phone Number', currentUser?.phone_number)}
                                            </div>
                                            <div className="rowData name g s">
                                                {/* {renderInputField('Last Login', currentUser?.last_login)} */}
                                                {renderInputField('Contract Limit', currentContractLimit)}
                                            </div>
                                        </div>

                                        <div className="row g s userPermissions ">
                                            <div className="header permissionsHeader bold cL s g dG">
                                                <ListAltIcon/>
                                                <div className="f cC gCW">
                                                    Permissions
                                                </div>
                                            </div>
                                            <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR2' : 'gC1 gR2'} dG`}>
                                                Upload
                                            </div>
                                            <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR3' : 'gC1 gR3'} s`}>
                                                <div className="trackBar cC g fR">
                                                    <div className="f cC">
                                                        <UploadTwoToneIcon/>
                                                    </div>
                                                    {uploadOptions.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className={`option bold f cC dP ${
                                                                currentUser?.uploadAllowed === option.value ? " active" : ""
                                                            }`}
                                                          
                                                        >
                                                            {option.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR2' : 'gC1 gR4'} dG`}>
                                                Download
                                            </div>
                                            <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR3' : 'gC1 gR5'} s`}>
                                                <div className="trackBar cC g fR">
                                                    <div className="f cC">
                                                        <DownloadTwoToneIcon/>
                                                    </div>
                                                    {uploadOptions.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className={`option bold f cC dP ${
                                                                currentUser?.downloadAllowed === option.value ? " active" : ""
                                                            }`}                                                         
                                                        >
                                                            {option.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={`header bold cL g ${viewType === "full"  ? 'gC1 gR4' : 'gC1 gR6'} dG`}>
                                                Account Creation
                                            </div>
                                            <div className={`rowData preferences g ${viewType === "full" ? 'gC1 gR5' : 'gC1 gR7'} s`}>
                                                <div className="trackBar cC g fR">
                                                    <div className="f cC">
                                                        <PersonAddAltTwoToneIcon/>
                                                    </div>
                                                    {uploadOptions.map((option) => (
                                                        <div
                                                            key={option.value}
                                                            className={`option bold f cC dP ${
                                                                currentUser?.creationAllowed === option.value ? " active" : ""
                                                            }`}                                                       
                                                        >
                                                            {option.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {session?.user?.data?.views?.includes("Research Hub") && 
                                                <>
                                                    <div className={`header bold cL g ${viewType === "full"  ? 'gC2 gR4' : 'gC1 gR8'} dG`}>
                                                        Research Hub
                                                    </div>
                                                    <div className={`rowData preferences g ${viewType === "full" ? 'gC2 gR5' : 'gC1 gR9'} s`}>
                                                        <div className="trackBar cC g fR">
                                                            <div className="f cC">
                                                                <TravelExploreIcon/>
                                                            </div>
                                                            {uploadOptions.map((option) => (
                                                                <div
                                                                    key={option.value}
                                                                    className={`option bold f cC dP ${
                                                                        currentUser?.researchAllowed === option.value ? " active" : ""
                                                                    }`}                                                       
                                                                >
                                                                    {option.label}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="actionBar f g cC fR dP gR4">
                                        {(editingAccount) &&
                                            <div className="btnWIcon g cC f bR s e p oH gC1 bold"
                                                onClick={()=>{
                                                    {editingAccount && setCurrentUser(tempAccountData);}                           
                                                    setCurrentlyEditing(false); setCreatingUser(false); setEditingAccount(false);
                                                    setTempCreatePermission(null); setTempUploadPermission(null); setTempDownloadPermission(null); setTempResearchPermission(null);
                                                }}
                                            >
                                                Cancel
                                            </div>
                                        }                               
                                        {(editingAccount) ?
                                            <div className="btnWIcon g cC f bR s e p oH gC3 bold"
                                                onClick={() => {
                                                    if(editingAccount){
                                                        updateAttributesInTeamMembers(selectedItemIndex, { 
                                                            first_name: currentUser?.first_name,
                                                            last_name: currentUser?.last_name,
                                                            email: currentUser?.email,
                                                            phone_number: currentUser?.phone_number,
                                                        });
                                                        editUser(
                                                            {
                                                                first_name: currentUser?.first_name,
                                                                last_name: currentUser?.last_name,
                                                                email: currentUser?.email,
                                                                phone_number: currentUser?.phone_number,
                                                                recordID: currentUser?.recordID
                                                            }
                                                        );
                                                        setEditingAccount(false);
                                                    }
                                                }}
                                            >
                                                Update 
                                            </div>
                                            :
                                            <div className="btnWIcon g cC f bR s e p oH gC3 bold inactive">
                                                Update
                                            </div>
                                        }
                                    </div>
                                    </>
                                }
                                {settingsWidget === 'emailPreferences' && 
                                    <>
                                        <div className='bold dP'>Email Preferences</div>   
                                        <Preferences
                                            session={session}
                                            sessionUser={sessionUser}
                                        />         
                                    </>
                                }
                                {settingsWidget === 'passReset' && 
                                    <div className='g dG'>
                                        <div className='bold dP cC'>Password Reset</div>   
                                        <div className='f cC g'>
                                            <PasswordReset
                                                session={session}
                                                sessionUser={sessionUser}
                                                settings={true}
                                                viewType={viewType}
                                            />     
                                        </div>        
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>  
                );
            default:
                return (
                    <div>Default option selected</div>
                );
          }
        
    }

    return (
        <div className="settingsContainer f g">
            <div className="settingsTabs cC f g">
                {session?.user?.data?.permissions?.includes('accMgr') && <div className={`${selectedTab === 'manageUsers' ? 'settingTabSelected' : 'settingTabDisabled p'} gC1 f dP cC`} onClick={()=>{setSelectedTab('manageUsers');{editingAccount && setCurrentUser(tempAccountData);} setSelectedUser(emptyUser); setSelectedItemIndex(null); setEditingAccount(false); setTempCreatePermission(null); setTempUploadPermission(null); setTempDownloadPermission(null); setTempResearchPermission(null);}}><div className='cC dG'>Manage Users<ManageAccountsIcon/></div></div>}
                <div className={`${selectedTab === 'settings' ? 'settingTabSelected' : 'settingTabDisabled p'} ${session?.user?.data?.permissions?.includes('accMgr') ? 'gC2' : 'gC1'}  f dP cC`} onClick={()=>{setSelectedTab('settings'); setCurrentlyEditing(false); setCreatingUser(false);}}><div className='cC dG'>Settings<SettingsIcon/></div></div>
                {/* <div className={`${selectedTab === 'changePassword' ? 'settingTabSelected' : 'settingTabDisabled p'} gC3 f dP`} onClick={()=>{setSelectedTab('changePassword')}}>Change Password</div> setSelectedUser(tempUserData); setTempUserData(null); */} 
            </div>
            <div className="f g">
                {printOverlayContent()}
            </div>
        </div>
    )
};

export default AdminBoard;