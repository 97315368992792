import React, { useEffect, useState, useRef} from 'react';

import * as XLSX from 'xlsx';

function UploadVars(props){

        //const session = props?.session;

        const defaultColumns = {
            
              plainArray : ["first_name", "last_name", "ssn", "dob", "city", "state", "zip_code"],
              detailedArray : [
                {
                    "id": "3",
                    "columnName": "first_name",
                    "friendlyTerm": "First Name",
                    "frozen": false
                },
                {
                    "id": "5",
                    "columnName": "last_name",
                    "friendlyTerm": "Last Name",
                    "frozen": false
                },
                {
                    "id": "7",
                    "columnName": "ssn",
                    "friendlyTerm": "Social Security Number",
                    "frozen": false
                },
                {
                    "id": "8",
                    "columnName": "dob",
                    "friendlyTerm": "Date of Birth",
                    "frozen": false
                },
                {
                    "id": "9",
                    "columnName": "city",
                    "friendlyTerm": "City",
                    "frozen": false
                },
                {
                    "id": "10",
                    "columnName": "state",
                    "friendlyTerm": "State",
                    "frozen": false
                },{
                    "id": "11",
                    "columnName": "zip_code",
                    "friendlyTerm": "Zip Code",
                    "frozen": false
                }
              ]
        }
        
        const allColumns = [
            {
                "id": "1",
                "columnName": "internal_group",
                "friendlyTerm": "Group",
                "alternateTerm": "Internal Group",
                "frozen": false
            },
            {
                "id": "2",
                "columnName": "internal_id",
                "friendlyTerm": "Internal ID",
                "frozen": false
            },
            {
                "id": "3",
                "columnName": "first_name",
                "friendlyTerm": "First Name",
                "frozen": false
            },
            {
                "id": "4",
                "columnName": "middle_name",
                "friendlyTerm": "Middle Name",
                "frozen": false
            },
            {
                "id": "5",
                "columnName": "last_name",
                "friendlyTerm": "Last Name",
                "frozen": false
            },
            {
                "id": "6",
                "columnName": "suffix",
                "friendlyTerm": "Suffix",
                "frozen": false
            },
            {
                "id": "7",
                "columnName": "ssn",
                "friendlyTerm": "Social Security Number",
                "mobileFriendlyTerm": "SSN",
                "frozen": false
            },
            {
                "id": "8",
                "columnName": "dob",
                "friendlyTerm": "Date of Birth",
                "frozen": false
            },
            {
                "id": "9",
                "columnName": "city",
                "friendlyTerm": "City",
                "frozen": false
            },
            {
                "id": "10",
                "columnName": "state",
                "friendlyTerm": "State",
                "frozen": false
            },
            {
                "id": "11",
                "columnName": "zip_code",
                "friendlyTerm": "Zip Code",
                "frozen": false
            },
            {
                "id": "12",
                "columnName": "benef_first_name",
                "friendlyTerm": "Beneficiary First Name",
                "frozen": false
            },
            {
                "id": "13",
                "columnName": "benef_middle_name",
                "friendlyTerm": "Beneficiary Middle Name",
                "frozen": false
            },
            {
                "id": "14",
                "columnName": "benef_last_name",
                "friendlyTerm": "Beneficiary Last Name",
                "frozen": false
            },
            {
                "id": "15",
                "columnName": "benef_dob",
                "friendlyTerm": "Beneficiary DOB",
                "frozen": false
            },
            {
                "id": "16",
                "columnName": "benef_city",
                "friendlyTerm": "Beneficiary City",
                "frozen": false
            },
            {
                "id": "17",
                "columnName": "benef_state",
                "friendlyTerm": "Beneficiary State",
                "frozen": false
            },
            {
                "id": "18",
                "columnName": "benef_zip_code",
                "friendlyTerm": "Beneficiary Zip",
                "frozen": false
            },
            {
                "id": "19",
                "columnName": "benef_relationship",
                "friendlyTerm": "Beneficiary Relationship",
                "frozen": false
            }
        ];

        const initialImportedList = {
            branch : "deathTrac",
            stem : "individuals",
            columns : [],
            list: undefined, //rows,
            name : undefined,
            ID : undefined,
            criteria : {
              current : {
                attr : undefined
              },  
            existing: [
                {
                    "attr": "record_id",
                    "criterion": {
                        "not_blank": true
                    },
                    "formType": "int",
                    "groupID": 1,
                    "groupOperator": "AND",
                    "inlineOperator": "AND"
                }
            ],
            },
            details : {
              name : "Upload Preview",
              description : undefined,
              shareType : undefined,
              shareList : undefined, 
              reoccurType  : false,
              scrollType : "infinite",
              editAccessList : ["owner"],
              viewAccessList : ["owner"],
            },
            query : undefined,
            showAll : false,
            // lastQuery : undefined,
            lastPageIndex : 1,
            lastRecordID : undefined,
            editCell : {
                "row" : undefined,
                "column" : undefined,
                "value" : ''
            }
        };

        const initialErrorCountData = {
            'file_name': '',
            'total_participants': 0,
            'first_name': 0,
            'last_name': 0,
            'ssn': 0,
            'dob': 0,
            'city': 0,
            'state': 0,
            'zip_code': 0,
        };

        const updateReport = (path, attr, value) => {
            setData((prevState) => {
              const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy
              const pathSegments = path.split('.');
              let current = newState;

              for (const segment of pathSegments) {
        
                if (segment.includes('[')) {
                  // Handle array access within the path
                  const [key, indexStr] = segment.split('[');
                  const index = parseInt(indexStr.replace(']', ''), 10);
        
                  if (!current[key]) {
                    // Initialize an array if it doesn't exist
                    current[key] = [];
                  }
        
                  if (!current[key][index]) {
                    // Initialize an object within the array if it doesn't exist
                    current[key][index] = {};
                  }
        
                  current = current[key][index];
                } else {
                  // Handle regular object properties within the path
                  if (!current[segment]) {
                    // Initialize an object if it doesn't exist
                    current[segment] = {};
                  }
        
                  current = current[segment];
                }
              }
        
              if (attr === null) {
                // marketValue, assignedUsersList, issueDate, accountManager, caseProcessor
                // Update the entire path's value to the new value
                newState[pathSegments[0]] = value;
              } else if (typeof attr === 'object' && !Array.isArray(attr)) {
                Object.assign(current, attr);
              } else {
                if(Array.isArray(attr)){
                  newState[pathSegments[0]] = attr;
                }else{
                  current[attr] = value;
                }
                // Update only the specified attribute
              }
        
              return newState;
            });
          };

        const updateImportedList = (attr, value, append) => {
            setData(prevState => {

               // Check if the attribute already exists and is an array
                //const existingAttr = Array.isArray(prevState.importedList[attr]) ? prevState.importedList[attr] : [];
                // Append the new value to the array
                let updatedAttr = null;
                if(append){
                    const existingAttr = Array.isArray(prevState.importedList[attr]) ? prevState.importedList[attr] : [];
                    updatedAttr = [...existingAttr, value];
                }else{
                    updatedAttr = value;
                }
                
                return {
                    ...prevState,
                    importedList: {
                        ...prevState.importedList,
                        [attr]: updatedAttr,
                    },
                };
            });
        }

        const updateRows = (index, newValue) => {
            const newRows = [...data?.rows]; 
            newRows[index] = newValue;
            setData(prevData => ({
                ...prevData,
                rows: newRows
            })); 
            //setRows(newRows);
        };

        const setFileTabSelected = (state) => {
            setData(prevData => ({
                ...prevData,
                fileTabSelected: state
            })); 
        }

        const statesArray = [
            'ALABAMA', 'AL', 'ALASKA', 'AK', 'ARIZONA', 'AZ', 'ARKANSAS', 'AR', 'CALIFORNIA', 'CA', 'COLORADO', 'CO',
            'CONNECTICUT', 'CT', 'DELAWARE', 'DE', 'FLORIDA', 'FL', 'GEORGIA', 'GA', 'HAWAII', 'HI', 'IDAHO', 'ID',
            'ILLINOIS', 'IL', 'INDIANA', 'IN', 'IOWA', 'IA', 'KANSAS', 'KS', 'KENTUCKY', 'KY', 'LOUISIANA', 'LA',
            'MAINE', 'ME', 'MARYLAND', 'MD', 'MASSACHUSETTS', 'MA', 'MICHIGAN', 'MI', 'MINNESOTA', 'MN', 'MISSISSIPPI', 'MS',
            'MISSOURI', 'MO', 'MONTANA', 'MT', 'NEBRASKA', 'NE', 'NEVADA', 'NV', 'NEW HAMPSHIRE', 'NH', 'NEW JERSEY', 'NJ',
            'NEW MEXICO', 'NM', 'NEW YORK', 'NY', 'NORTH CAROLINA', 'NC', 'NORTH DAKOTA', 'ND', 'OHIO', 'OH', 'OKLAHOMA', 'OK',
            'OREGON', 'OR', 'PENNSYLVANIA', 'PA', 'RHODE ISLAND', 'RI', 'SOUTH CAROLINA', 'SC', 'SOUTH DAKOTA', 'SD', 'TENNESSEE', 'TN',
            'TEXAS', 'TX', 'UTAH', 'UT', 'VERMONT', 'VT', 'VIRGINIA', 'VA', 'WASHINGTON', 'WA', 'WEST VIRGINIA', 'WV',
            'WISCONSIN', 'WI', 'WYOMING', 'WY','DC', 'WASHINGTON DC', 'D.C.', 'DISTRICT OF COLUMBIA'
        ];

        const InvalidSSNList = [
            '123456789',
            '987654321',
            '123123123',
            '111111111',
            '222222222',
            '333333333',
            '444444444',
            '555555555',
            '666666666',
            '777777777',
            '888888888',
            '999999999',
            '000000000',
        ];

        function excelSerialDateToDate(serialDate, dateOrigin = '1900') {
            let baseDate;
            if (dateOrigin === '1900') {
              // Excel incorrectly thinks 1900 is a leap year, so we subtract 1 for dates after 1900-02-28
              baseDate = new Date(1900, 0, (serialDate > 59 ? serialDate - 1 : serialDate - 0));
            } else if (dateOrigin === '1904') {
              // This is for Excel files created on Mac before Excel 2011
              baseDate = new Date(1904, 0, serialDate - 1);
            } else {
              throw new Error('Invalid date origin');
            }  
            return baseDate;
        }

        function dateToExcelSerialDate(dateString) {
            // Parse the date string into a JavaScript Date object
            const date = new Date(dateString);
        
            // Set the start date as January 1, 1900
            const startDate = new Date('1899-12-31');
        
            // Calculate the difference in days
            const diffInTime = date - startDate;
            const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
        
            // Add one day for Excel's leap year bug
            return diffInDays;
        }

        function excelSerialDateToString(serialDate, dateOrigin = '1900') {
            // Convert the Excel serial date to a JavaScript Date object
            const date = excelSerialDateToDate(serialDate, dateOrigin);
        
            // Format the date as MM/DD/YYYY
            const formattedDate = (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
                                  date.getDate().toString().padStart(2, '0') + '/' +
                                  date.getFullYear().toString();
        
            return formattedDate;
        }

        function setPrevIndividuals(prevNum) {
            setData(prevData => ({
                ...prevData,
                prevNumIndividuals: prevNum
            })); 
        }

        function setSftpFiles(file) {
            setData(prevData => ({
                ...prevData,
                sftpFiles: file
            })); 
        }

        //////////////////////////////// Download Excel file from imported list //////////////////////////////

        function getFriendlyTerm(attributesData, header) {
            for (let key in attributesData) {
                if (key === header) {
                    return attributesData[key].friendlyTerm;
                }
            }
            return null;
        }

        function transformedJson(rowData, attributeData) {
            // Moved outside the map to avoid repeated computations
            const rowKeys = Object.keys(rowData[0] || {});
        
            return rowData.map(item => {
                const newItem = {};

                rowKeys.forEach(header => {
                    let newKey = getFriendlyTerm(attributeData, header);
                    if (header === 'internal_group') {
                        newKey = "Internal Group";
                    } else if (header === 'ssn') {
                        newKey = "SSN";
                    }
        
                    newItem[newKey || header] = item[header]; // Simplified assignment
                });
                
                return newItem; // Return transformed item
            });
        }

        function exportToExcel(rowData, fileName, attributeData) {

            const transformedData = transformedJson(rowData, attributeData);
            // Create a new workbook and a new worksheet
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(transformedData);
        
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        
            // Write the workbook to a file
            XLSX.writeFile(workbook, `${fileName}.xlsx`);
        }

        function convertToISODate(dateString) {
            // If the dateString is null or empty, return it directly
            if (!dateString) return dateString;
            
            // Split the date string into year, month, and day
            const [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
            
            // Check if the parsed parts are valid numbers
            if (isNaN(year) || isNaN(month) || isNaN(day)) {
              // If any part is not a valid number, return the original dateString
              return dateString;
            }
          
            // Check if the month is in the valid range (1 to 12)
            if (month < 1 || month > 12) {
              return dateString;
            }
          
            // Check if the day is in the valid range for the given month and year
            if (day < 1 || day > new Date(year, month, 0).getDate()) {
              return dateString;
            }
            
            // Create a new Date object using the parsed values
            const date = new Date(year, month - 1, day); // month is zero-based
            
            // Check if the date object is valid
            if (isNaN(date.getTime())) {
              // If the date object is invalid, return the original dateString
              return dateString;
            }
            
            // Return the ISO formatted date string
            return date.toISOString();
          }


        //////////////////////////////// Edit cells variables /////////////////////////////////
        const inputRef = useRef(null);

        let editingCell = null;
        let editValue = '';

        let displayErrors = false;
        
        // const [editingCell, setEditingCell] = useState(null);
        // const [editValue, setEditValue] = useState('');

        const editCell = (rowIndex, cellIndex, cellValue) => {

            setData(prevData => ({
                ...prevData,
                editValue: cellValue,
                editingCell: { rowIndex, cellIndex }
            })); 

            //setEditingCell({ rowIndex, cellIndex });
            //setEditValue(cellValue);
        };

        const saveEdit = (newValue, rowIndex, header) => {
            // Update the cell value in data structure

            
            let editObj = {
                initialState: data.rows[rowIndex][header],
                modifiedState: newValue,
                index : {rowIndex, header}
            }
            data.editList.forEach(eObj => {
                if(eObj.index.rowIndex === rowIndex && eObj.index.header === header){
                    editObj = {
                        initialState: eObj.modifiedState,
                        modifiedState: newValue,
                        index : {rowIndex, header}
                    }
                }
            });
            
            // editObj = {
            //     initialState: data.rows[rowIndex][header],
            //     modifiedState: newValue,
            //     index : {rowIndex, header}
            // }

            addObjectToEditList(editObj);
            //editList.push(editObj); 
            
            if(header=="ssn"){
            let numberSSN = parseInt(newValue,10);
                updateRowValue(rowIndex, header, numberSSN);
                //data.rows[rowIndex][header] = numberSSN;
            }else if(header=="dob"){
                const newDate = dateToExcelSerialDate(newValue)+1;
    
                updateRowValue(rowIndex, header, newDate);
                    //data.rows[rowIndex][header] = newDate;
                }
            else{
 
                updateRowValue(rowIndex, header, newValue);
                //data.rows[rowIndex][header] = newValue;
            }

            //setEditingCell(null); // Exit editing mode
            setData(prevData => ({
                ...prevData,
                editingCell: null
            })); 
        };

        const updateRowValue = (rowIndex, header, newValue) => {
            setData(prevData => {
                const newRows = [...prevData.importedList.list];
        
                if (newRows[rowIndex]) {
                    newRows[rowIndex] = {
                        ...newRows[rowIndex],
                        [header]: newValue
                    };
                }

                // Return the new state object with the updated rows array
                return {
                    ...prevData,
                    importedList: {
                        ...prevData.importedList,
                        list: newRows
                    }
                };
            });
        };

        function validateEdit(cellValue, header) {
            let setRed = false;

            if((cellValue==='-')&& (header==="dob"||header==="first_name"||header==="last_name"||header==="ssn"||header==="city"||header==="state"||header==="zip_code")){
                setRed = true
            }
            if((header==="ssn") &&  (String(cellValue).length !== 9 || InvalidSSNList.includes(String(cellValue)) || !/^\d+$/.test(cellValue))) {
                setRed = true;
            }
            if((header==="first_name" || header==="last_name") && !/^[A-Za-z-' ]+$/.test(cellValue)) {
                setRed = true;
            }  
            if(header == 'dob' && ((!isValidDate(cellValue)))){
                setRed = true;
            }
            // if(header === 'dob' && ((!isValidDate(cellValue)))){
            //     setRed = true;
            // }
            // if(header === 'dob' && typeof cellValue !== 'number'){
            //     setRed = true;
            // }  
            if(header === 'city' && !/^[A-Za-z-' .]+$/.test(String(cellValue))){
                setRed = true;
            }
            if(header === 'state' &&  !statesArray.includes(cellValue?.toUpperCase())){
                setRed = true;
            }
            if (header === 'zip_code' && !/^\d{5}(?:-\d{4})?$/.test(cellValue) ){
                setRed = true;
            }

            return setRed;
        };

        function isValidDate(d) {
            // Regular expression to check if the date is in MM/DD/YYYY format
            return /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(d);
        }

        const addObjectToEditList = (newObject) => {
            if(newObject.modifiedState === newObject.initialState){
                return;
            }else{
                setData(prevData => {
                    // Create a new array with all old items, and add the new object
                    const newEditList = [...prevData.editList, newObject];
            
                    // Return the new state object with the updated editList
                    return {
                        ...prevData,
                        editList: newEditList
                    };
                });
            }
        };

        function decrementErrorCount(attr) { 
            if (data?.errorCountData?.[attr] > 0) {
                functions?.updateReport(`errorCountData`, attr, data?.errorCountData?.[attr] - 1);
            }
        }
        function incrementErrorCount(attr) { 
            if (data?.errorCountData?.[attr] >= 0) {
                functions?.updateReport(`errorCountData`, attr, data?.errorCountData?.[attr] + 1);
            }
        }

        // Upload History
        function getHistory(session){
            const sessionUserString = localStorage.getItem('sessionUser');
            let sessionUser = null;
            if (sessionUserString) {
                sessionUser = JSON.parse(sessionUserString);
            }
            setData(prevData => ({
                ...prevData,
                historyLoading: true,
                accountNumber: session?.handler?.data?.currentAccountID
            })); 
            //setLoadingStatus(true);
            let paramVals = {
                "sessionToken" : sessionUser.sessionToken,
                "accountID" : session?.handler?.data?.currentAccountID,
            };
    
            session?.env?.functions?.buildFetchRequest("user/uploads", paramVals, undefined, undefined, "GET")
              .then(response => response.json())
              .then(resData => {
                //setLoadingStatus(false);
                if(resData.status === 200){
                    setData(prevData => ({
                        ...prevData,
                        historyData: resData.accountData,
                        historyLoading: false
                    })); 
                    //setIsHistory(resData.accountData);
                }else{
                    setData(prevData => ({
                        ...prevData,
                        historyLoading: false
                    })); 
                    //setErrorStatus(resData.message);
                }
            });
        }

        function getSftpFiles(session){
            const sessionUserString = localStorage.getItem('sessionUser');
            let sessionUser = null;
            if (sessionUserString) {
                sessionUser = JSON.parse(sessionUserString);
            }
            //setLoadingStatus(true);
            let paramVals = {
                "sessionToken" : sessionUser?.sessionToken,
                "accountID" : session?.handler?.data?.currentAccountID,
            };
    
            session?.env?.functions?.buildFetchRequest(`sftp/retrieve/data?accountID=${session?.handler?.data?.currentAccountID}`, paramVals, undefined, undefined, "GET")
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        //throw new Error(`Unexpected response status: ${response.status}`);
                    }
                })
                .then(resData => {
                //setLoadingStatus(false);
                const newSftpHistory = [];
                const newSftpRetrievable = [];
                resData?.forEach(file => {
                    if (file.sentByAbl === 0) {
                        newSftpHistory.push(file); 
                    } else if (file.sentByAbl === 1) {
                        newSftpRetrievable.push(file); 
                    }
                });

                setData(prevData => ({
                    ...prevData,
                    sftpHistory: newSftpHistory,
                    sftpRetrievable: newSftpRetrievable,
                }));
            });
        }

        async function downloadSftpFile(session, fileName, originalFileName) {
            // debugger;
            const sessionUserString = localStorage.getItem('sessionUser');
            let sessionUser = null;
        
            if (sessionUserString) {
                sessionUser = JSON.parse(sessionUserString);
            }
        
            let paramVals = {
                "sessionToken": sessionUser.sessionToken,
                "accountID": session?.handler?.data?.currentAccountID,
            };
        
            const headers = new Headers({
                'Authorization': `Bearer ${sessionUser?.sessionID}`
            });
        
            try {
                const fetchUrl = new URL('https://apistg.abltech.com/sftp/retrieve/file');
                fetchUrl.searchParams.append('accountID', session?.handler?.data?.currentAccountID);
                fetchUrl.searchParams.append('filename', fileName);

                const response = await fetch(fetchUrl, { headers });

                if (response.status !== 200) {
                    setData(prevData => ({
                        ...prevData,
                        retrievableDownloading: null
                    }));
                    throw new Error(`Failed to download file. Status: ${response.status}`);
                }
        
                if (response.status === 200) {
                    const reader = response.body.getReader();
                    const contentLength = response.headers.get('Content-Length');

                    
                    let receivedLength = 0; // received that many bytes at the moment
                    let chunks = []; // array of received binary chunks (comprises the body)
                    while(true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        setData(prevData => ({
                            ...prevData,
                            retrievableDownloading: null
                        }));
                        const progressBar = document.querySelector('.retrievableDownloadBar');
                        progressBar?.style.setProperty("--progress", `${0}%`);  
                        progressBar.style.width = `${0}%`;
                        break;
                    }

                    chunks.push(value);
                    receivedLength += value.length;
                    //console.log(`Received ${receivedLength} of ${contentLength}`)

                    const percentage = (receivedLength/contentLength) * 100

                    const progressBar = document.querySelector('.retrievableDownloadBar');
                    const val = Math.floor(percentage);
                    progressBar?.style.setProperty("--progress", `${val}%`);  
                    progressBar.style.width = `${percentage}%`;
                    
                    }
                    
                    functions?.setDownloadRow(null);

                    // Step 4: concatenate chunks into single Uint8Array
                        let blob = new Blob(chunks);
                        // Fallback to the older method
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = originalFileName?.trim();
                        document.body.appendChild(a);
                        a.click();
                        URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                }
                
            } catch (error) {
                // Log errors encountered during download
                console.error('Download failed:', error);
            }
        }

        function setDownloadRow(rowIndex){
            setData( prevData => ({
                ...prevData,
                currentDownloadRow: rowIndex,
            }));
        }

        // Main references
        const [data, setData] = useState({
            fileTabSelected: 'many',
            files: [],
            headers: [],
            importedList: initialImportedList, 
            initialErrorCount: initialErrorCountData,
            errorCountData: initialErrorCountData,
            rowChangeCount: 1, // Used for checking invalid entries on Upload
            transformedJson,
            historyData:[],
            singleHistoryData:[],
            historyLoading: false,
            accountNumber:'',
            prevNumIndividuals: null,
            singleUploadSuccess: false,
            contractLimit: 10,
            removeFile: false,
            largeUpload: false,
            largeUploadCount: null,
            sftpFiles: [],
            sftpHistory: [],
            sftpRetrievable: [],
            currentDownloadRow: null,
            retrievableDownloading: null,
            csvErrorsCounted: false,
        });        

        const functions = {
            setFileTabSelected,
            updateReport, // Used for Upload
            convertToISODate, // String to ISO 
            excelSerialDateToDate, // Used for user agreement
            excelSerialDateToString,
            exportToExcel, // Used to download excelfile
            decrementErrorCount,
            incrementErrorCount,
            getHistory,
            updateRowValue,
            setPrevIndividuals,
            setSftpFiles,
            getSftpFiles,
            downloadSftpFile,
            setDownloadRow,
        }
  
        const uploadVars = {
            data,
            setData,
            functions,
            allColumns,
            inputRef,
        }
        
    return uploadVars;
};

export default UploadVars;

