import React from 'react';
import { useState, useEffect, useRef } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import InputField from "../Components/InputField.js";
import ViewBarBtn from '../Components/ViewBarBtn.js';
import ReportSheet from '../Components/Reporting/ReportSheet.js';
import ReportList from '../Components/Reporting/ReportList.js';

function Reporting(props){
    const [requestingBatch, setRequestingBatch] = useState(undefined);
    const [search, setSearch] = useState({
        searching : props?.viewType !== "mobile" ? true : undefined,
        searchValue : ''
    });
    const dataPointerStems = [
        "deathTrac",
        "lifeTrac",
    ];

    // const [sortedListResults, setSortedListResults] = useState();
    const [reportingView, setReportingView] = useState("list");
    const [editingRowValues, setEditingRowValues] = useState();
    const [editCellDropdownHandler, setEditCellDropdownHandler] = useState({ showMenu : undefined });
    const [editMode, setEditMode] = useState(false);

    const reportTable = useRef(null);

    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const allReports = props?.session?.reporting?.data[currentAccountID]?.allReports;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const updateReporting = session?.reporting?.setData;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const userOpportunityTypes = session?.user?.data?.opportunityTypes;

    const initialReportCategory = selectedReport?.stem || (userOpportunityTypes[1] && userOpportunityTypes[1]) || "deceased";
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);

    const [reportCategory, setReportCategory] = useState(initialReportCategory);
    const reportCategoryObj = {reportCategory, setReportCategory}

    const [existingColumnsCopy, setExistingColumnsCopy] = useState(selectedReport?.columns);

    const rowMaxString = localStorage.getItem('storage');
    const rowMax = JSON.parse(rowMaxString);

    /* Dropdowns */
    const [pagination, setPagination] = useState({
        showMenu : false,
        rowMax : rowMax?.reporting?.rowMax ?? 100,
        index : selectedReport?.lastPageIndex ?? 1,
    });
    const [selectedFolder, setSelectedFolder] = useState({
        name : "Show All",
        showMenu : false
    });
    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            // Check if showMenu is already true
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            // If showMenu is not true, return the previous state without changes
            return prev;
        });
    };

    const resetDropdowns = () => {
        if(pagination?.showMenu){
            resetShowMenu(setPagination);
        }
        if(selectedFolder?.showMenu){
            resetShowMenu(setSelectedFolder);
        }
        if(editCellDropdownHandler?.showMenu){
            resetShowMenu(setEditCellDropdownHandler);
        }
    };

    const requestObject = {
        session,
        requestingBatch,
        setRequestingBatch,
        pagination,
        setExistingColumnsCopy
    }

    useEffect(() => {
        if(selectedReport?.stem && reportCategory !== selectedReport?.stem){
            setReportCategory(selectedReport?.stem);
        }
    }, [selectedReport?.stem]);

    useEffect(() => {
        if(selectedReport?.query){
            if(reportType === "inherited" || selectedReport?.stem === "scheduled"){
                const items = props?.session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[selectedReport?.branch]?.[selectedReport?.stem];
                const filteredResults = reporting?.functions?.filterItemsWithQuery(selectedReport?.query, items);
                updateReporting(`${session?.handler?.data?.currentAccountID}.selectedReport`, "list", filteredResults);
                setEditingRowValues([]);
                setEditMode(selectedReport?.editable === "always" ?? false);
            }
        }

        if(selectedReport?.lastRecordID !== selectedReport?.recordID){
            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'sorting', []);
            // setExistingColumnsCopy(selectedReport?.columns);
            props?.session?.reporting?.functions?.updateSelectedReport("lastPageIndex", 1, session?.handler?.data?.currentAccountID);    
            setPagination((prev) => ({
                ...prev,
                index: 1,
            }));
        }

        setSearch({
            searching: false,
            searchValue: '',
        });

        props?.session?.reporting?.functions?.updateSelectedReport("lastRecordID", selectedReport?.recordID, session?.handler?.data?.currentAccountID);
        if (reportTable.current) {
            reportTable.current.scrollLeft = 0;
        }
    }, [
        selectedReport?.recordID,
        ...dataPointerStems.map(key => session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.[key])
    ]);

    useEffect(() => {
        if(search?.searching === true && props?.viewType === "mobile"){
            document?.getElementById('searchBarInput')?.focus();
        }else{
            setSearch((prevSearch) => ({
                ...prevSearch,
                searchValue: '',
            }));
        }
    }, [search?.searching]);

    useEffect(() => {
        
        // session?.reporting?.functions?.initializeData(Object.keys(session?.account?.data));
        
        updateReporting(`${currentAccountID}`, 'reportListOpen', true);
        let storedProfileOpen = localStorage.getItem('profileOpen');
        storedProfileOpen = storedProfileOpen ? JSON.parse(storedProfileOpen) : {};
        if (storedProfileOpen[currentAccountID] !== undefined) {
          updateReporting(`${currentAccountID}`, 'profileOpen', storedProfileOpen[currentAccountID]);
        } else {
          const profileOpenValue = reporting?.data[currentAccountID]?.profileOpen;
          if (profileOpenValue !== undefined && profileOpenValue !== null) {
            storedProfileOpen[currentAccountID] = profileOpenValue;
            localStorage.setItem('profileOpen', JSON.stringify(storedProfileOpen));
          }
        }
      }, []);
      
      useEffect(() => {
        let storedProfileOpen = localStorage.getItem('profileOpen');
        const existingProfileOpen = storedProfileOpen ? JSON.parse(storedProfileOpen) : {};
        const updatedProfileOpen = {
          ...existingProfileOpen,
          [currentAccountID]: reporting?.data[currentAccountID]?.profileOpen
        };
        if (updatedProfileOpen[currentAccountID] !== null) {
          localStorage.setItem('profileOpen', JSON.stringify(updatedProfileOpen));
        }
      }, [reporting?.data[currentAccountID]?.profileOpen]);

      useEffect(() => {
        // setReportCategory(userOpportunityTypes?.[0])
    }, [userOpportunityTypes]);

    return (
        <div className="reportingPage g bR" onClick={resetDropdowns}>
            <div className="reportingHub g oH">
                {props?.viewType === "mobile" &&
                    <div className="reportingNavBar f g cC">
                        <div className="f cC t">
                            <div className="viewBar cC">
                                <ViewBarBtn
                                    isActive={reportingView === "list"}
                                    onClick={() => setReportingView("list")}
                                    label="All Reports"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                                <ViewBarBtn
                                    isActive={reportingView === "report"}
                                    onClick={() => setReportingView("report")}
                                    label="Selected Report"
                                    alertType={"Status"}
                                    viewType={props?.viewType}
                                    session={session}
                                    currentPolicy={props?.currentPolicy}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className={`${reporting?.data[currentAccountID]?.profileOpen ? "actionBar" : "fullActionBar"} g fR cC s${search?.searching ? " searching" : ''}${props?.viewType === "mobile" ? " " + reportingView : ''} ${reportType ?? "generated"}`}>
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "list")) &&
                        <>
                            { reporting?.data[currentAccountID]?.reportListOpen ?
                                <div className='cC dG f reportListHeader g'>
                                    {props?.viewType !== "mobile" &&
                                        <div
                                            className="btn cR p gC1"
                                            onClick={()=>{
                                                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'reportListOpen', false);
                                            }}
                                        >
                                            <ArrowCircleLeftOutlinedIcon/>
                                        </div>
                                    }
                                    <div className="bold gC2">
                                        {reportCategory ? `${reportCategory?.charAt(0)?.toUpperCase() + reportCategory?.slice(1)} Reports` : "Select Category"}
                                    </div>
                                </div>
                                :
                                <div className='cL f '>
                                    {props?.viewType !== "mobile" &&
                                        <div
                                            className="btn cR p gC1"
                                            onClick={()=>{
                                                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'reportListOpen', true);
                                            }}
                                        >
                                            <ArrowCircleRightOutlinedIcon/>
                                        </div>   
                                    }
                                </div>
                            }
                        </>
                    }
                    <div className={`${reportType === "inherited" ? 'reportSheetTitle' : 'reportSheetIndividualTitle'} f g`}>
                        {reportType === "inherited" &&
                            <div className="searchContainer f g cC gR1">
                                {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report")) && reportType === "inherited" &&
                                    <div className={`searchBtn f s p cL fR lH${search?.searching ? " active" : ''}`}>
                                        <div
                                            className="btn cC"
                                            onClick={() => {
                                                if(props?.viewType === "mobile"){
                                                    setSearch((prevSearch) => ({
                                                        ...prevSearch,
                                                        searching: !search?.searching,
                                                    }));
                                                }
                                            }}
                                        >
                                            {search?.searching && props?.viewType === "mobile" ?
                                                <SearchOffIcon/>
                                            :
                                                <SearchIcon/>
                                            }
                                        </div>
                                    </div>
                                }
                                {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report" && search?.searching)) &&
                                    (reportType === "inherited" || props?.viewType === "mobile") &&
                                    <div className={`searchBar g f bold cC fR fC`}>
                                        <InputField
                                            search={true}
                                            value={search?.searchValue ?? ''}
                                            placeholder={"Search Individuals"}
                                            name={"searchValue"}
                                            readOnly={false}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            setter={setSearch}
                                            autoComplete={"off"}
                                            id={"searchBarInput"}
                                        />
                                    </div>
                                }
                            </div>
                        }
                        {reportType === "dynamic" && selectedReport?.subReport?.list &&
                            <div className="f cL">
                                <div
                                    className="btnWIcon gC2 bR dG cC p bold lH"
                                    onClick={()=>{
                                        const subReport = {...selectedReport?.subReport};
                                        subReport.list = undefined;
                                        subReport.columns = undefined;        
                                        subReport.name = undefined;        
                                        updateReporting(`${session?.handler?.data?.currentAccountID}.selectedReport`, "subReport", subReport);
                                    }}
                                >
                                    <ChevronLeftIcon/>
                                    <div className="f cC e">
                                        {selectedReport?.details?.name ?? "Unnamed Report"}
                                    </div>
                                </div>
                            </div>
                        }
                        {(props?.viewType !== "mobile"
                            || (props?.viewType === "mobile" && reportingView === "report" && !search?.searching)
                            || reportType === "inherited" && reportingView === "report" && !search?.searching) &&
                            <div className={`reportTitle gR1 gC2 g f bold cC fR fC ${props?.viewType === "mobile" ? "gC2" : ""}`} >
                                {`${selectedReport?.details?.name ?? "Unnamed Report"}${selectedReport?.subReport?.name ?? ''}`}
                            </div>
                        }
                        {(props?.viewType !== "mobile") &&
                            <div
                                className={`btnOptions cR e g fR fC f dG gR1 gC3 ${selectedReport?.editable ? " editable" : ''}`}
                            >
                                {(reportType === "inherited" || reportType === "dynamic") && session?.user?.data?.permissions?.includes("download") &&
                                    <div
                                        className={`btnWIcon bold bR fMP cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                                        onClick={viewableReport && selectedReport?.list?.length ? ()=>{session?.reporting?.data[currentAccountID]?.downloadDateType == 'YYYY-MM-DD' ? reporting?.functions?.downloadReport(session) : reporting?.functions?.downloadReport(session, true)} : null}
                                    >
                                        <div className="f cC lH">
                                            Download
                                        </div>
                                        <DownloadIcon/>
                                    </div>
                                }                           
                                <div
                                    className="btn more cR p"
                                    onClick={()=>{
                                        session?.env?.setOverlay("reportOptions");
                                    }}
                                >
                                    <MoreHorizIcon/>
                                </div>
                                {/* {reportType === "inherited" && selectedReport?.editable &&
                                    <div
                                        className={`btnWIcon bold bR fMP cC ns p${editMode ? " filled" : ''}`}
                                        onClick={()=>{setEditMode(!editMode)}}
                                    >
                                        <div className="f cC lH">
                                            {`${editMode ? "Editing" : "Edit"}`}
                                        </div>
                                        <EditIcon/>
                                    </div>
                                } */}
                            </div>
                        }
                    </div>

                    { !reporting?.data[currentAccountID]?.profileOpen ?
                        props?.viewType !== 'mobile' && (
                            <>
                                <div
                                    className="btn cR p f e"
                                    onClick={()=>{
                                        updateReporting(`${session?.handler?.data?.currentAccountID}`, 'profileOpen', true);
                                    }}
                                >
                                    <ArrowCircleLeftOutlinedIcon/>
                                </div>
                            </>
                        )
                        :  
                        props?.viewType !== 'mobile' && (
                            <div className='gC g f cC profileTitle'>    
                                <div className='bold'>Individual Profile</div>                 
                                <div
                                    className="btn cR f p e"
                                    onClick={()=>{
                                        updateReporting(`${session?.handler?.data?.currentAccountID}`, 'profileOpen', false);
                                    }}
                                >
                                    <ArrowCircleRightOutlinedIcon/>
                                </div>
                            </div> 
                        )
                    }
                    {(props?.viewType === "mobile" && reportingView === "report") &&
                        <div className={
                            `btnOptions cC g fR fC f dG ${(props?.viewType === "mobile"
                            && reportingView === "report"
                            && search?.searching) ?
                            "gC2" : "gC3"}`}
                        >
                            {reportType === "inherited" && session?.user?.data?.permissions?.includes("download") &&
                                <div
                                    className={`btn circle bold bR fMP cC ns${!viewableReport ? " inactive" : " p"}`}
                                    onClick={viewableReport ? ()=>{ reporting?.functions?.downloadReport(selectedReport, selectedReport?.sortedListResults)} : null}
                                >
                                    <DownloadIcon/>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={`reportBuilder f fR g s oH${reporting?.data[currentAccountID]?.reportListOpen ? " reportListOpen" : " reportListClosed"}`}>
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "list")) &&
                        <ReportList
                            session={session}
                            allReports={allReports}
                            requestObject={requestObject}
                            reportCategoryObj={reportCategoryObj}
                            selectedFolder={{data : selectedFolder, set : setSelectedFolder}}
                            resetDropdowns={resetDropdowns}
                            reportingView={{data : reportingView, set : setReportingView}}
                        />
                    }
                    {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView === "report")) &&
                        <ReportSheet
                            session={props?.session}
                            requestObject={requestObject}
                            reportType={reportType}
                            search={{ data : search, set : setSearch}}
                            reportCategoryObj={reportCategoryObj}
                            pagination={{data : pagination, set : setPagination}}
                            editingRowValues={{data : editingRowValues, set : setEditingRowValues}}
                            // sortedListResults={{data : sortedListResults, set : setSortedListResults}}
                            resetDropdowns={resetDropdowns}
                            editCellDropdownHandler={{
                                data: editCellDropdownHandler,
                                set: (newValue) => setEditCellDropdownHandler(prev => ({ ...prev, showMenu: newValue }))
                            }}
                            editMode={editMode}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Reporting;